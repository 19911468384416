












/**
 * @Author mcastrucci
 * Bioamrker result edit component
 * This View implements a BiomarkerResultForm component
 * it only interacts with the form and updates the biomarker result when submited
 * This component should be used when we want the UI to display this content as the whole view
 */
import { Component, Prop } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import { dispatchGetBiomarkersForList, dispatchUpdateBiomarkerResult } from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IUpdateBiomarkerResult } from '@/interfaces/biomarkerResults';
import BiomarkerResultForm from '@/components/forms/BiomarkerResultForm.vue';

@Component({ components: { DataPicker, BiomarkerResultForm } })
export default class EditBiomarkerResult extends AppComponent {
  @Prop({ type: String }) public biomarkerResultId: string;


  /**
   * Cancels the operation and goes back to admin panels
   */
  public cancel() {
    this.$router.back();
  }

  public mounted() {
    this.$bus.$emit('biomarker-results-form:reset');
    this.fetchData();
  }

  public async fetchData() {
    await dispatchGetBiomarkersForList(this.$store);
  }

  /*
    It Submits the biomarker result update by checking if the model values have values
    If they dont, they are not included in the API call
  */
  public async submit(biomarkerResult: IUpdateBiomarkerResult) {
    const result = await dispatchUpdateBiomarkerResult(this.$store, {
        biomarkerResultId: this.biomarkerResultId,
        biomarkerResult,
    });

    if (result) {
      this.$router.push('/main/admin/biomarkerresults');
    }
  }
}
